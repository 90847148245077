import React from "react"
import styled, { css } from "styled-components"

const inputStyles = css`
  color: #999;
  letter-spacing: 1px;
  background: #fff;
  border: 2px solid #fff;
  padding: 5px 10px;
  font-size: 12px;
  margin-bottom: 25px;
`

const inputTransition = css`
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
`
const FormWrapper = styled.section`
  width: 80%;
  margin: 0 auto;
`
const FormMessageInput = styled.textarea`
  ${inputStyles}
  ${inputTransition}
  width: 100%;
  height: 150px;
`

const FormTextInput = styled.input`
  ${inputStyles}
  ${inputTransition}
  width: 100%;
  padding: 5px;
  &::-webkit-input-placeholder {
    font-size: 12px;
  }
`

const FormButton = styled.input`
  ${inputTransition}
  width: 100%;
  font-size: 12px;
  padding: 5px 10px;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  background: #4a5054;
  color: #f5f5f5;
  width: auto;

  &:hover {
    background: #708280;
  }
`
const FormContainer = styled.div``
const Contact = props => (
  <div className="container contact-form">
    <div class="contact-header">
      <h2 class="text-center">Get In Touch</h2>
      <p class="text-center">
        I will be glad to answer questions or give you an estimate on your
        project
      </p>
    </div>
    <FormWrapper>
      <form
        name="contact"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <div className="row">
          <FormTextInput
            type="text"
            name="name"
            id="name"
            placeholder="Name *"
          />

          <FormTextInput
            type="text"
            name="email"
            id="email"
            placeholder="Email *"
          />

          <FormMessageInput
            name="message"
            id="message"
            placeholder="Tell Me About Your Project *"
          ></FormMessageInput>
          <FormButton
            type="submit"
            name="btnSubmit"
            className="btnContact"
            value="Send Message"
          />
        </div>
      </form>
    </FormWrapper>
  </div>
)

export default Contact
