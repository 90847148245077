import React from "react"
import Layout from "../components/layout"
import Contact from "../components/Contact"
// import Header from "./header"

export default ({ pageContext }) => (
  <Layout>
    <Contact />
  </Layout>
)
